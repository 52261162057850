// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "GM 👋",
  title2: "Charles",
  logo_name: "charlie.b()",
  nickname: "charlie",
  first_name: 'Charlie',
  full_name: "Charles Buckley",
  subTitle: "Fullstack Web/Blockchain Developer. Forever learning.",
  resumeLink:
    "https://charlesb-resume.tiiny.site/",
  mail: "mailto:charlesbuckley122@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/0xagf0x",
  linkedin: "https://www.linkedin.com/in/charles-buckley-20447950/",
  gmail: "charlesbuckley122@gmail.com",
  twitter: "https://twitter.com/0xagf0x",
};

const skills = {
  data: [
    {
      title: "Web",
      fileName: "FullStackImg",
      skills: [
        "⚡ Expertise in designing and building scalable web applications using modern frameworks like React, Angular, or Vue.js",
        "⚡ Hands-on experience with component-based architecture for reusable and maintainable UI components.",
        "⚡ Skilled in creating advanced animations and transitions with CSS, JavaScript, and libraries like GSAP or Framer Motion",
        "⚡ Skilled in back-end development with technologies like Node.js, Express, or Python (Django)"
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "simple-icons:csswizardry",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "Tailwind",
          fontAwesomeClassname: "simple-icons:tailwindcss",
          style: {
            color: "#60b4ed",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "React",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Typescript",
          fontAwesomeClassname: "simple-icons:typescript",
          style: {
            color: "#3178C6",
          },
        },
        {
          skillName: "NextJS",
          fontAwesomeClassname: "simple-icons:nextdotjs",
          style: {
            color: "#172b4d",
          },
        },
        {
          skillName: "ThreeJS",
          fontAwesomeClassname: "logos:threejs",
          style: {
            color: "#3178C6",
          },
        },
        {
          skillName: "Django",
          fontAwesomeClassname: "simple-icons:django",
          style: {
            color: "#7377AD",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Blockchain",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Designed, developed, and deployed robust custom Smart Contracts on leading blockchain platforms, including Ethereum, Polygon, and Solana",
        "⚡ Experience working with the Ethereum Virtual Machine (EVM) and writing well-structured, thoroughly tested Solidity code",
        "⚡ Skilled in using Web3.js, ethers.js, and related libraries to integrate blockchain features into applications effectively",
        "⚡ Practical expertise with token standards such as ERC-20, ERC-721, and ERC-1155, implementing tokenization for a variety of use cases",
      ],
      softwareSkills: [
        {
          skillName: "Solidity",
          fontAwesomeClassname: "simple-icons:solidity",
          style: {
            color: "#949494",
          },
        },
        {
          skillName: "Etherum",
          fontAwesomeClassname: "simple-icons:ethereum",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Polygon",
          fontAwesomeClassname: "simple-icons:polygon",
          style: {
            color: "#7641dc",
          },
        },
        {
          skillName: "Solana",
          fontAwesomeClassname: "simple-icons:solana",
          style: {
            color: "#3faedd",
          },
        },
        {
          skillName: "React",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Mocha",
          fontAwesomeClassname: "simple-icons:mocha",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "EthersJS",
          fontAwesomeClassname: "simple-icons:ethers",
          style: {
            color: "#000",
          },
        },
       
      ],
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Check out my work history",
  description: "Check out my work history",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Frontend Developer",
          company: "Metagenics",
          company_url: "https://metagenics.com/",
          logo_path: "logo-metagenics.webp",
          duration: "March 2024 - Present",
          location: "Remote",
          description1:
            "- Lead frontend initiatives for the Marketing team, driving feature development and ensuring alignment with strategic goals.",
          description2:
            "- Developed and optimized reusable components and patterns, enhancing consistency and scalability.",
          description3: '- Enhanced SEO and performance by implementing SSR with frameworks like Next.js and employing dynamic imports for efficient code splitting.',  
          color: "rgb(127, 141, 170)",
        },
        {
          title: "Fullstack Developer",
          company: "Mobelux",
          company_url: "https://mobelux.com/",
          logo_path: "mobelux.png",
          duration: "Dec 2019 - March 2024",
          location: "Remote",
          description1:
            "- Developed, deployed, and maintained scalable, high-performance web applications using various JavaScript libraries (React, Typescript), and Python (Django).",
          description2:
            "- Collaborated with cross-functional teams, applying Agile methodologies to deploy and enhance new features.",
          description3: '- Designed and implemented RESTful API’s and GraphQL endpoints, ensuring data consistency and optimal performance.',  
          color: "rgb(127, 141, 170)",
        },
        {
          title: "Lead Developer",
          company: "AliensAmongUs.io",
          company_url: "https://x.com/_AliensAmongUs",
          logo_path: "aau.png",
          duration: "Nov 2021 - Nov 2022 (Contract)",
          location: "Remote",
          description1:
            "- Developed a fullstack Web3 dApp using Next.js, Web3.js, Solidity, and Bootstrap, enabling seamless blockchain integration for decentralized applications.",
          description2:
            "- Optimized on-chain and off-chain interactions, ensuring seamless performance and reducing gas costs for end users.",
          description3:
            "-  Collaborated on feature development and system improvements, enhancing the platform’s scalability and user experience.",
          color: "rgb(127, 141, 170)",
        },
        {
          title: "UI/UX Developer",
          company: "Fusion / Allianz Global",
          company_url: "https://www.allianztravelinsurance.com/",
          logo_path: "fusion.png",
          duration: "May 2019 - Dec 2019 (Contract)",
          location: "Richmond, VA",
          description1: "- Transformed wireframes and mockups into responsive, interactive web components using React and modern JavaScript frameworks.",
          description2: "- Developed and maintained a component-based design system, ensuring consistency across the application.",
          description3: "- Collaborated with client representatives and cross-functional teams to provide tailored solutions that aligned with business goals.",
          color: "rgb(127, 141, 170)",
        },
        {
          title: "Front End Developer",
          company: "Maxx Potential",
          company_url: "https://maxxpotential.com/",
          logo_path: "maxx_potential.png",
          duration: "Dec 2017 - May 2019",
          location: "Richmond, VA",
          description1: "- Built custom client websites using WordPress, JavaScript, PHP, and CSS.",
          description2: "- Conducted peer code reviews to uphold high-quality coding standards and ensure deliverables were optimized and error-free.",
          description3: "- Refactored legacy codebases, leading to a significant improvement in application speed and maintainability.",
          color: "rgb(127, 141, 170)",
        },
        {
          title: "Front End Developer",
          company: "Denver Disco (contract)",
          company_url: "https://www.facebook.com/DenverDisco",
          logo_path: "denver_disco.jpeg",
          duration: "Aug 2017 - Nov 2017",
          location: "Remote",
          description1: "- Built client websites using WordPress while modifying existing code as needed.",
          description2: "- Worked hand in hand with the QA Department on a customized testing plan and developed custom themes.",
          description3: "- Helped shape IT strategic vision and drive key departmental objectives.",
          color: "rgb(127, 141, 170)",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "Random assortment of projects",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "pfp-transparent.png",
    description:
      "Lets chat",
  },
  blogSection: {
    title: "Blogs/Twitter",
    subtitle:
      "I write on Twitter. Mostly about tech and crypto",
    link: "https://twitter.com/0xagf0x",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      name: "Ski Resort Weather",
      url: "https://ski-map.vercel.app/",
      github_link: "https://github.com/0xagf0x/ski-weather",
      description:
        "Ski Weather is an interactive web app that displays ski resorts on a Mapbox map, providing weather forecasts, webcams, and favorites using WeatherAPI.",
      languages: [
        {
          name: "React",
          iconifyClass: "logos:react",
        },
        {
          name: "MapBox",
          iconifyClass: "logos:mapbox",
        },
        {
          name: "CSS",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
      ],
    },
    {
      name: "ThreeJS Landing Page",
      url: "https://threejs-scroller.vercel.app/",
      github_link: "https://github.com/0xagf0x/threejs-scroller",
      description:
        "A 3D Scroller application built with Three.js, React, and Vite.",
      languages: [
        {
          name: "ThreeJS",
          iconifyClass: "logos:threejs",
        },
        {
          name: "React",
          iconifyClass: "logos:react",
        },
        {
          name: "CSS",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
      ],
    },
    {
      name: "WAV Player",
      url: "https://wav-player.vercel.app/",
      github_link: "https://github.com/0xagf0x/wav-player",
      description:
        "A music PWA to store and play local .wav files.",
      languages: [
        {
          name: "Typescript",
          iconifyClass: "vscode-icons:file-type-typescript",
        },
        {
          name: "CSS",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
      ],
    },
    {
      name: "Coin Tracker",
      url: "https://coin-tracker-amber.vercel.app/",
      github_link: "https://github.com/0xagf0x/coin-tracker",
      description:
        "This project provides users with real-time tracking of the top 100 cryptocurrencies. It allows users to monitor the prices and information of their favorite coins.",
      languages: [
        {
          name: "Typescript",
          iconifyClass: "vscode-icons:file-type-typescript",
        },
        {
          name: "Tailwind",
          iconifyClass: "vscode-icons:file-type-tailwind",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
      ],
    },
    {
      name: "Dinkers Club",
      url: "https://dinkersclub.com/",
      description:
        "A custom Shopify site I helped build for a client. I was responsible for the front-end development and design.",
      languages: [
        {
          name: "Shopify",
          iconifyClass: "logos:shopify",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      name: "My Blog",
      url: "https://nextjs-blog-cbuckley88.vercel.app/",
      github_link: "https://github.com/0xagf0x/nextjs-blog",
      description:
        "A basic blog built with NextJS and GraphQL",
      languages: [
         {
          name: "NextJS",
          iconifyClass: "logos:nextjs-icon",
        },
        {
          name: "GraphQL",
          iconifyClass: "mdi:graphql",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
          {
          name: "Tailwind",
          iconifyClass: "vscode-icons:file-type-tailwind",
        },
      ],
    },
      {
      name: "NFT Battle Game",
      url: "https://github.com/0xagf0x/battle_game.eth",
      github_link: "https://github.com/0xagf0x/battle_game.eth",
      description:
        "A turn-based NFT game where users can team up to battle a boss",
      languages: [
        {
          name: "Solidity",
          iconifyClass: "vscode-icons:file-type-solidity",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      name: "Web3 Kanban Board",
      url: "https://web3-trello.vercel.app/",
      github_link: "https://github.com/0xagf0x/web3-trello",
      description:
        "A kanban board, built on the Ethereum blockchain (Goerli network). Built using Solidity, React, Ethers.JS and Hardhat",
      languages: [
        {
          name: "Solidity",
          iconifyClass: "vscode-icons:file-type-solidity",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      name: "PNS (Poot Name Service)",
      url: "https://polygon-domain-services-lac.vercel.app/",
      github_link: "https://github.com/0xagf0x/polygon-domain-services",
      description:
        "Create your own DNS on the Layer-2 Polygon blockchain (Mumbai testnet)",
      languages: [
        {
          name: "Solidity",
          iconifyClass: "vscode-icons:file-type-solidity",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
     {
      name: "Turkey Timer",
      url: "https://turkey-timer.vercel.app/",
      github_link: "https://github.com/0xagf0x/turkey-timer",
      description:
        "I was sick of forgetting how long to roast a turkey, so I made this with Vue and Tailwind.",
      languages: [
        {
          name: "Vue",
          iconifyClass: "vscode-icons:file-type-vue",
        },
        {
          name: "Tailwind",
          iconifyClass: "vscode-icons:file-type-tailwind",
        },
         {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
      ],
    },
    {
      name: "Todo app",
      url: "https://svelte-todo-hazel.vercel.app/",
      github_link: "https://github.com/0xagf0x/svelte-todo",
      description:
        "A basic to-do built with Svelte and Tailwind",
      languages: [
        {
          name: "Svelte",
          iconifyClass: "vscode-icons:file-type-svelte",
        },
        {
          name: "Tailwind",
          iconifyClass: "vscode-icons:file-type-tailwind",
        },
         {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
      ],
    },
     {
      name: "Tailwind Landing Page",
      url: "https://0xagf0x.github.io/tailwind-landing-page/",
      github_link: "https://github.com/0xagf0x/tailwind-landing-page",
      description:
        "A basic landing page built with Tailwind CSS",
      languages: [
         {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "Tailwind",
          iconifyClass: "logos:tailwindcss-icon",
        },
      ],
    },
    {
      name: "Basic Weather App",
      url: "https://weather-juexlrglm-cbuckley88.vercel.app/",
      github_link: "https://github.com/0xagf0x/weather-app",
      description:
        "A basic weather app built with React and OpenWeather API",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
          {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
      ],
    },
    {
      name: "NFT Minting for OpenSea",
      url: "https://github.com/0xagf0x/nft_collection.eth",
      github_link: "https://github.com/0xagf0x/nft_collection.eth",
      description:
        "Create a randomly generated NFT, viewable on OpenSea",
      languages: [
        {
          name: "Solidity",
          iconifyClass: "vscode-icons:file-type-solidity",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
     {
      name: "Trvia Game",
      url: "https://trivia-game-lemon.vercel.app/",
      description:
        "A simple trivia game built with React",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
      ],
    },
    {
      name: "DeFi Staking App",
      url: "https://github.com/0xagf0x/defi-staking-app",
      github_link: "https://github.com/0xagf0x/defi-staking-app",
      description:
        "A basic Tether(USDT) minter + staking application",
      languages: [
        {
          name: "Solidity",
          iconifyClass: "vscode-icons:file-type-solidity",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Bootstrap",
          iconifyClass: 'logos-bootstrap',
        },
      ],
    },
    {
      name: "Kickstarter.eth",
      url: "https://github.com/0xagf0x/kickstart.eth",
      github_link: "https://github.com/0xagf0x/kickstart.eth",
      description:
        "A crowd funding app built for the Ethereum blockchain",
      languages: [
        {
          name: "Solidity",
          iconifyClass: "vscode-icons:file-type-solidity",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name:"Semantic UI",
          iconifyClass: 'logos:semantic-ui',
        },
      ],
    },
    {
      name: "Binance market data collector",
      url: "https://github.com/0xagf0x/binance-api-tool",
      github_link: "https://github.com/0xagf0x/binance-api-tool",
      description:
        "A tool used for retrieving crypto market data",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      name: "Lottery.eth",
      url: "https://github.com/0xagf0x/react-lottery.eth",
      github_link: "https://github.com/0xagf0x/react-lottery.eth",
      description:
        "A very lightweight lottery app built using Solidity + Web3js",
      languages: [
        {
          name: "Solidity",
          iconifyClass: "vscode-icons:file-type-solidity",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      name: "The Press Hook",
      url: "https://www.thepresshook.com/",
      description:
        "A custom Django/Wagtail application I helped build with Mobleux, used by journalists and publicists to connect with emerging brands.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "SCSS",
          iconifyClass: "vscode-icons:file-type-scss",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Django",
          iconifyClass: "logos-django",
        },
      ],
    },
    {
      name: "Covid-19 Tracker",
      url: "https://basic-covid-19-tracking-app.herokuapp.com/",
      description:
        "A Covid-19 tracking app built with React and Material-UI",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      name: "Image Finder",
      url: "https://basic-pixabay-img-search.herokuapp.com/",
      description:
        "An image search results app built with React Pixabay API",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      name: "Drum Machine",
      url: "/drum_machine/index.html",
      github_link: "https://github.com/0xagf0x/drum-machine",
      description:
        "Fun key-based drum machine built using JavaScript",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      name: "NY Times Fetcher",
      url: "/ny_times/index.html",
      description:
        "Fetches news articles using NY Times API",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
      ],
    },
    {
      name: "MS Paint Lite",
      url: "/painter/index.html",
      description:
        "A lightweight MS Paint clone. Create your own work of art!",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      name: "Calculator Clone",
      url: "https://github.com/0xagf0x/react-iOS-calculator",
      github_link: "https://github.com/0xagf0x/react-iOS-calculator",
      description:
        "This is a simple calculator app",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
       {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};