import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import { Fade } from "react-reveal";
import "./ContactComponent.css";
import { greeting, contactPageData } from "../../portfolio.js";
import { style } from "glamor";
import snowflakeImg from "../../assests/images/snowflake.png";

// 1) Snow initialization function (no internal requestAnimationFrame here).
function initializeSnowLarge(scene) {
  let particles;
  const positions = [];
  const velocities = [];

  const numSnowFlakes = 10;
  const maxRange = 1000;
  const minRange = maxRange / 2;
  const minHeight = 500;

  const geometry = new THREE.BufferGeometry();
  const textureLoader = new THREE.TextureLoader();
  const snowflakeTexture = textureLoader.load(snowflakeImg);

  function addSnowFlakes() {
    for (let i = 0; i < numSnowFlakes; i++) {
      positions.push(
        Math.random() * maxRange - minRange,
        Math.random() * maxRange + minHeight,
        Math.random() * maxRange - minRange
      );
      velocities.push(
        (Math.random() * 6 - 1) * 0.1,
        Math.random() * 1.35 + 0.05,
        (Math.random() * 5 - 1) * 0.1
      );
    }
    geometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(positions, 3)
    );
    geometry.setAttribute(
      "velocity",
      new THREE.Float32BufferAttribute(velocities, 3)
    );

    const flakeMaterial = new THREE.PointsMaterial({
      size: 250,
      map: snowflakeTexture,
      blending: THREE.AdditiveBlending,
      depthTest: false,
      transparent: true,
      opacity: 0.8,
      color: 0xffffff,
    });

    particles = new THREE.Points(geometry, flakeMaterial);
    scene.add(particles);
  }

  addSnowFlakes();

  function updateParticles() {
    const posArray = geometry.attributes.position.array;
    const velArray = geometry.attributes.velocity.array;
    for (let i = 0; i < numSnowFlakes * 3; i += 3) {
      posArray[i] -= velArray[i];
      posArray[i + 1] -= velArray[i + 1];
      posArray[i + 2] -= velArray[i + 2];

      if (posArray[i + 1] < 0) {
        posArray[i] = Math.random() * maxRange - minRange;
        posArray[i + 1] = Math.random() * minRange + minHeight;
        posArray[i + 2] = Math.random() * maxRange - minRange;
      }
    }
    geometry.attributes.position.needsUpdate = true;
  }

  return updateParticles;
}

function initializeSnowMed(scene) {
  let particles;
  const positions = [];
  const velocities = [];

  // Number of snowflakes and positioning constraints
  const numSnowFlakes = 500;
  const maxRange = 1000;
  const minRange = maxRange / 2;
  const minHeight = 500;

  // Create the geometry & load texture
  const geometry = new THREE.BufferGeometry();
  const textureLoader = new THREE.TextureLoader();
  // Adjust the path to your snowflake image if necessary
  const snowflakeTexture = textureLoader.load(snowflakeImg);

  // Sets up the initial snowflake positions and velocities
  function addSnowFlakes() {
    for (let i = 0; i < numSnowFlakes; i++) {
      positions.push(
        Math.random() * maxRange - minRange, // x
        Math.random() * maxRange + minHeight, // y
        Math.random() * maxRange - minRange // z
      );

      velocities.push(
        (Math.random() * 6 - 1) * 0.1, // x velocity range: -0.1 to 0.1
        Math.random() * 1.35 + 0.05, // y velocity range: 0.05 to 0.4 (downward)
        (Math.random() * 5 - 1) * 0.1 // z velocity range: -0.1 to 0.1
      );
    }

    geometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(positions, 3)
    );
    geometry.setAttribute(
      "velocity",
      new THREE.Float32BufferAttribute(velocities, 3)
    );

    // Create a PointsMaterial for the snowflakes
    const flakeMaterial = new THREE.PointsMaterial({
      size: 25,
      map: snowflakeTexture,
      blending: THREE.AdditiveBlending,
      depthTest: false,
      transparent: true,
      opacity: 0.8,
      color: 0xffffff,
    });

    // Build the Points object and add it to the scene
    particles = new THREE.Points(geometry, flakeMaterial);
    scene.add(particles);
  }

  addSnowFlakes();

  // Update each snowflake's position on every frame
  function updateParticles() {
    const posArray = geometry.attributes.position.array;
    const velArray = geometry.attributes.velocity.array;

    for (let i = 0; i < numSnowFlakes * 3; i += 3) {
      // Move the snowflake according to its velocity
      posArray[i] -= velArray[i];
      posArray[i + 1] -= velArray[i + 1];
      posArray[i + 2] -= velArray[i + 2];

      // If it falls below y=0, reset near the top
      if (posArray[i + 1] < 0) {
        posArray[i] = Math.random() * maxRange - minRange;
        posArray[i + 1] = Math.random() * minRange + minHeight;
        posArray[i + 2] = Math.random() * maxRange - minRange;
      }
    }

    // Notify Three.js that positions have been updated
    geometry.attributes.position.needsUpdate = true;
  }

  // Return the function we call each frame to animate snow
  return updateParticles;
}

function initializeSnowSmall(scene) {
  let particles;
  const positions = [];
  const velocities = [];

  // Number of snowflakes and positioning constraints
  const numSnowFlakes = 2500;
  const maxRange = 1000;
  const minRange = maxRange / 2;
  const minHeight = 500;

  // Create the geometry & load texture
  const geometry = new THREE.BufferGeometry();
  const textureLoader = new THREE.TextureLoader();
  // Adjust the path to your snowflake image if necessary
  const snowflakeTexture = textureLoader.load(snowflakeImg);

  // Sets up the initial snowflake positions and velocities
  function addSnowFlakes() {
    for (let i = 0; i < numSnowFlakes; i++) {
      positions.push(
        Math.random() * maxRange - minRange, // x
        Math.random() * maxRange + minHeight, // y
        Math.random() * maxRange - minRange // z
      );

      velocities.push(
        (Math.random() * 6 - 1) * 0.1, // x velocity range: -0.1 to 0.1
        Math.random() * 1.35 + 0.05, // y velocity range: 0.05 to 0.4 (downward)
        (Math.random() * 5 - 1) * 0.1 // z velocity range: -0.1 to 0.1
      );
    }

    geometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(positions, 3)
    );
    geometry.setAttribute(
      "velocity",
      new THREE.Float32BufferAttribute(velocities, 3)
    );

    // Create a PointsMaterial for the snowflakes
    const flakeMaterial = new THREE.PointsMaterial({
      size: 10,
      map: snowflakeTexture,
      blending: THREE.AdditiveBlending,
      depthTest: false,
      transparent: true,
      opacity: 0.8,
      color: 0xffffff,
    });

    // Build the Points object and add it to the scene
    particles = new THREE.Points(geometry, flakeMaterial);
    scene.add(particles);
  }

  addSnowFlakes();

  // Update each snowflake's position on every frame
  function updateParticles() {
    const posArray = geometry.attributes.position.array;
    const velArray = geometry.attributes.velocity.array;

    for (let i = 0; i < numSnowFlakes * 3; i += 3) {
      // Move the snowflake according to its velocity
      posArray[i] -= velArray[i];
      posArray[i + 1] -= velArray[i + 1];
      posArray[i + 2] -= velArray[i + 2];

      // If it falls below y=0, reset near the top
      if (posArray[i + 1] < 0) {
        posArray[i] = Math.random() * maxRange - minRange;
        posArray[i + 1] = Math.random() * minRange + minHeight;
        posArray[i + 2] = Math.random() * maxRange - minRange;
      }
    }

    // Notify Three.js that positions have been updated
    geometry.attributes.position.needsUpdate = true;
  }

  // Return the function we call each frame to animate snow
  return updateParticles;
}

const ContactData = contactPageData.contactSection;

function Contact(props) {
 // ---------------------
  // 1) Refs for each <canvas>
  // ---------------------
  const bigSnowCanvasRef = useRef(null);  // For the Large Snow
  const bgCanvasRef      = useRef(null);  // For the Medium + Small Snow

  // ---------------------------------------------
  // 2) Large Snow: separate canvas, separate scene
  // ---------------------------------------------
  useEffect(() => {
    // 2a) Create a new Scene/Camera/Renderer for Large Snow
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      100000
    );
    camera.position.set(0, 800, 800);
    camera.lookAt(0, 0, 0);

    // 2b) Renderer for Large Snow, attached to bigSnowCanvasRef
    const renderer = new THREE.WebGLRenderer({ alpha: true, canvas: bigSnowCanvasRef.current });
    renderer.setSize(window.innerWidth, window.innerHeight);

    // 2c) Initialize Large Snow
    const updateSnowLarge = initializeSnowLarge(scene);

    // 2d) Animate loop for large snow
    function animate() {
      requestAnimationFrame(animate);
      updateSnowLarge();
      renderer.render(scene, camera);
    }
    animate();

    function onResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    }
    window.addEventListener("resize", onResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", onResize);
      // We don't remove the canvas from the DOM because we control it directly in the JSX
    };
  }, []);

  // --------------------------------------------------
  // 3) Medium & Small Snow: one combined background
  // --------------------------------------------------
  useEffect(() => {
    // 3a) Create scene/camera for background flakes
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      100000
    );
    camera.position.set(0, 800, 800);
    camera.lookAt(0, 0, 0);

    // 3b) Renderer for BG (medium+small), using bgCanvasRef
    const renderer = new THREE.WebGLRenderer({ alpha: true, canvas: bgCanvasRef.current });
    renderer.setSize(window.innerWidth, window.innerHeight);

    // 3c) Initialize medium + small snow
    const updateSnowMed   = initializeSnowMed(scene);
    const updateSnowSmall = initializeSnowSmall(scene);

    // 3d) Animate loop
    function animate() {
      requestAnimationFrame(animate);
      updateSnowMed();
      updateSnowSmall();
      renderer.render(scene, camera);
    }
    animate();

    function onResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    }
    window.addEventListener("resize", onResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);


  // --------------------------
  // 4) Card rotation effect
  // --------------------------
  useEffect(() => {
    const card = document.querySelector(".contact-heading-text-div");
    if (!card) return;

    function handleMouseMove(e) {
      const rect = card.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const centerX = rect.width / 2;
      const centerY = rect.height / 2;
      const rotateX = ((y - centerY) / centerY) * 15;
      const rotateY = ((x - centerX) / centerX) * -15;
      card.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(1.05)`;
    }
    function handleMouseLeave() {
      card.style.transform = "rotateX(0deg) rotateY(0deg) scale(1)";
    }
    card.addEventListener("mousemove", handleMouseMove);
    card.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      card.removeEventListener("mousemove", handleMouseMove);
      card.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  // 5) Theming
  const theme = props.theme;
  const styles = style({
    backgroundColor: `${theme.accentBright}`,
    ":hover": { boxShadow: `0 5px 15px ${theme.accentBright}` },
  });

  // 6) Render the Contact component
  return (
    <div className="contact-main">
      {/* 
        -- Large Snow Canvas (Front or Behind) --
        If you want it in front of everything, 
        give it a higher z-index with CSS. 
      */}
      <canvas 
        ref={bigSnowCanvasRef}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -2, // Adjust as desired
          pointerEvents: "none",
        }}
      />
      
      {/* 
        -- Background Canvas for Medium + Small Snow --
        This one can have zIndex: -3 or something 
        so it's behind the large snow, or reversed if you prefer
      */}
      <canvas 
        ref={bgCanvasRef}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -3,
          pointerEvents: "none",
        }}
      />

      <Header theme={theme} setTheme={props.setTheme} />
      <div className="basic-contact">
        <Fade bottom duration={1000} distance="40px">
          <div className="contact-heading-div">
            <div className="contact-heading-text-div">
              <h1
                className="contact-heading-text !bg-red-400"
                style={{ color: theme.text }}
              >
                {ContactData.title}
              </h1>
              <p className="contact-header-detail-text">
                {ContactData.description}
              </p>
              <SocialMedia />
              <br />
              <br />
              <a
                {...styles}
                className="general-btn"
                href={greeting.resumeLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                See my Resume
              </a>
            </div>
            <div className="contact-heading-img-div">
              <img
                className="profile-pic"
                src={require(`../../assests/images/${ContactData.profile_image_path}`)}
                alt=""
              />
            </div>
          </div>
        </Fade>
      </div>
      <Footer theme={props.theme} onToggle={props.onToggle} />
    </div>
  );
}

export default Contact;
