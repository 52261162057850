import React from "react";
import "./Footer.css";
import { Fade } from "react-reveal";

export default function Footer(props) {
  const theme = props.theme;

  return (
    <div className="footer-div">
      <Fade>
          <a
            className="footer-text"
            style={{ color: theme.accentColor }}
            href="/"
          >
            charlesb.me
          </a>
          <p
            className="footer-text sm"
            style={{ color: props.theme.secondaryText }}
          >
            0x790b13E497e28381242D1D8e51bc42332EFbfbaf
          </p>
      </Fade>
    </div>
  );
}
